 <template>
    <div>
    <!-- 列表 -->
    <div class="user">
      <page-table
        ref="dataTable"
        :data="shoporder"
        @changeCurrentPage="changeCurrent"
      >
        <el-table-column
          prop="week"
          label="周期"
          align="center"
          :resizable="false"
        ></el-table-column>
         <el-table-column
          prop="week_time"
          label="生成时间"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="end_week_time"
          label="开始时间"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="total"
          label="总积分"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="userNum"
          label="人数"
          align="center"
          :resizable="false"
        ></el-table-column>
        <el-table-column
          prop="total_price"
          label="总价格"
          align="center"
          :resizable="false"
        ></el-table-column>
      </page-table>
    </div>
  </div>
</template>

<script>
import { checkPermission } from "@/utils/permissions";
import { mallscoreList} from "@/request/api";
import pageTable from "@/components/pageTable.vue";
export default {
  name: "specialorder",
  components: {
    pageTable,
  },
  data() {
    return {
      page: {
        //分页信息
        page: 1, //当前页
        limit: 10, //每页条数
        total: 0, //总条数
      },
      order_id: "",
      money: "",
      shoporder:[]
    };
  },
  created() {
    this.shoporderlist();
  },

  mounted() {},
  methods: {
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.page = page;
      this.page.limit = size;
      this.shoporderlist();
    },
    async shoporderlist() {
      this.token = sessionStorage.getItem("token");
      let res = await mallscoreList({
        token: sessionStorage.getItem("token"),
        page: this.page.page,
        limit: this.page.limit,
      });
      if (res.status == 200) {
        console.log(res,'list');
        this.shoporder = res.data.data;
        this.$refs.dataTable.setPageInfo({
          total: res.data.count,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.census {
  padding-left: 80px;
}
</style>
